import { useFlags } from "launchdarkly-react-client-sdk";
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next";

export enum MigrationPhase {
    NOT_STARTED,
    AWARENESS,
    ONE,
    TWO,
    THREE,
    FORCE_MIGRATION,
    OKTA_LOGIN_ONLY
}

type BannerConfig = {
    migrationEnabled: boolean,
    showVerifyEmailCta: boolean,
    dismissible: boolean
    bannerMessage?: string,
    isWarningPhase?: boolean,
    hasPassedCutoffDate?: boolean,
}

export const useOktaMigrationBannerConfig = () => {
    const { t } = useTranslation();
    const [config, setConfig] = useState<BannerConfig>({ migrationEnabled: false, showVerifyEmailCta: false, dismissible: false });
    const { isOktaEnabled, oktaMigrationPhase } = useFlags();

    useEffect(() => {
        if (!isOktaEnabled || oktaMigrationPhase <= MigrationPhase.NOT_STARTED) {
            return; // Returning since Okta is disabled or the migration process has not started.
        }

        let updatedConfig: BannerConfig = { ...config, migrationEnabled: true };

        if (oktaMigrationPhase === MigrationPhase.FORCE_MIGRATION) {
            updatedConfig = { ...updatedConfig, hasPassedCutoffDate: true };
        } else {
            switch (oktaMigrationPhase) {
                case MigrationPhase.AWARENESS:
                    updatedConfig.bannerMessage = t("OKTA_MIGRATION_AWARENESS_BANNER_MESSAGE");
                    break;
                case MigrationPhase.ONE:
                    updatedConfig.bannerMessage = t("OKTA_MIGRATION_PHASE_ONE_BANNER_MESSAGE");
                    updatedConfig.dismissible = true;
                    updatedConfig.showVerifyEmailCta = true;
                    break;
                case MigrationPhase.TWO:
                case MigrationPhase.THREE:
                    updatedConfig.bannerMessage = t("OKTA_MIGRATION_PHASE_TWO_AND_THREE_BANNER_MESSAGE");
                    updatedConfig.dismissible = true;
                    updatedConfig.showVerifyEmailCta = true;
                    if (oktaMigrationPhase === MigrationPhase.THREE) {
                        updatedConfig.isWarningPhase = true
                    };
                    break;
                default:
                    break;
            }
        }

        setConfig(updatedConfig);
    }, [isOktaEnabled, oktaMigrationPhase])

    return config;
}
